/* tslint:disable */
/* eslint-disable */
/**
 * Mesensei API v5
 * Mesensei API v5
 *
 * The version of the OpenAPI document: 5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Document,
  Image,
} from '../models/index';
import {
    DocumentFromJSON,
    DocumentToJSON,
    ImageFromJSON,
    ImageToJSON,
} from '../models/index';

export interface GetImageRequest {
    identifier: string;
    size?: GetImageSizeEnum;
}

export interface UploadDocumentRequest {
    file?: Blob;
}

export interface UploadImageRequest {
    identifier?: string;
    image?: Blob;
}

/**
 * 
 */
export class FilesApi extends runtime.BaseAPI {

    /**
     * Get image in the preferred size. This returns the actual image, not the image object and related data.
     */
    async getImageRaw(requestParameters: GetImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['identifier'] == null) {
            throw new runtime.RequiredError(
                'identifier',
                'Required parameter "identifier" was null or undefined when calling getImage().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/files/images/{identifier}`.replace(`{${"identifier"}}`, encodeURIComponent(String(requestParameters['identifier']))).replace(`{${"size"}}`, encodeURIComponent(String(requestParameters['size']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Get image in the preferred size. This returns the actual image, not the image object and related data.
     */
    async getImage(requestParameters: GetImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.getImageRaw(requestParameters, initOverrides);
    }

    /**
     * Upload a new file document
     */
    async uploadDocumentRaw(requestParameters: UploadDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Document>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['file'] != null) {
            formParams.append('file', requestParameters['file'] as any);
        }

        const response = await this.request({
            path: `/files/documents`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentFromJSON(jsonValue));
    }

    /**
     * Upload a new file document
     */
    async uploadDocument(requestParameters: UploadDocumentRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Document> {
        const response = await this.uploadDocumentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upload a new image
     */
    async uploadImageRaw(requestParameters: UploadImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Image>> {
        const queryParameters: any = {};

        if (requestParameters['identifier'] != null) {
            queryParameters['identifier'] = requestParameters['identifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['image'] != null) {
            formParams.append('image', requestParameters['image'] as any);
        }

        const response = await this.request({
            path: `/files/images`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ImageFromJSON(jsonValue));
    }

    /**
     * Upload a new image
     */
    async uploadImage(requestParameters: UploadImageRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Image> {
        const response = await this.uploadImageRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetImageSizeEnum = {
    Original: 'original',
    Full: 'full',
    Card: 'card',
    List: 'list'
} as const;
export type GetImageSizeEnum = typeof GetImageSizeEnum[keyof typeof GetImageSizeEnum];
