import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "./table";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useState } from "react";
import { Tag } from "../../apiClient/generated/models/Tag";

//TODO: introduce filters

const mockData: Tag[] = [
  {
    id: "d5993f36-9434-5986-9dcf-c0ee2b4cd731",
    imageUrl: "",
    name: "IT",
    description: "",
    userCount: 0,
    groupCount: 0,
    postCount: 0,
    pageCount: 0,
    eventCount: 0,
  },
  {
    id: "070c0e02-7f20-5f24-a53a-a3bc8c63fdf3",
    imageUrl: "",
    name: "ATK",
    description: "",
    userCount: 0,
    groupCount: 0,
    postCount: 0,
    pageCount: 0,
    eventCount: 0,
  },
];

const columnHelper = createColumnHelper<Tag>();

export default function DataTable({ data }: { data: Tag[] }) {
  const [dataSet, _setData] = useState(() => [...data]);

  const columns = Object.keys(dataSet[0]).map((key: string) =>
    columnHelper.accessor(
      //@ts-ignore
      key,
      {
        id: key,
        cell: (info) => info.getValue(),
        header: () => key,
      },
    ),
  );

  const table = useReactTable({
    data: dataSet,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="bg-white">
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHead key={header.id} className="border font-bold">
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())}
                </TableHead>
              ))}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows.map((row) => (
            <TableRow key={row.id} className="hover:bg-muted">
              {row.getVisibleCells().map((cell) => (
                <TableCell key={cell.id} className="border">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
